* {
  background-color: #2f3136
  ;
}

h1 {
  display: grid;
  place-items: center;
  color: #ffffff;
  font-size: 100px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  margin-left: -70px;
}

img {
  height: 150px;
  width: 150px;
  display: grid;
  place-items: center;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  transition: all ease 0.8s;
}

div.gallery {
  margin: 5px;
  float: left;
  width: 180px;
  place-items: center;
}

div.gallery img {
  width: 100%;
  height: auto;
  display: grid;
  place-items: center;
}

img:hover {
  transform: scale(1.1);
}

.sixth {
  margin-left: 320px;
}

.fifth {
  margin-left: 510px;
  margin-top: 250px;
}

.forth {
  margin-left: 700px;
  margin-top: 500px;
}

.third {
  margin-left: 0px;
}

.second {
  margin-left: 190px;
  margin-top: 250px;
}

.first {
  margin-left: 380px;
  margin-top: 500px;
}

.bigns {
  color: #ffffff;
  user-select: none;
  margin-left: 20px;
  font-size: 40px;
}